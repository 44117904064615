
  .scroll-to-top {
    position: fixed;
    bottom: 25px;
    right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.3s ease;
    background-color: none;
  }
  